import axios from "axios";

const BaseURL: string = "https://websitecalculator.ecosail.app/v2/api/";

export const globalAPI = axios.create({
  baseURL: `${BaseURL}`,
  headers: {
    "Content-type": "application/json",
  },
  //withCredentials: true
});
