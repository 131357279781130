import { queryKeys } from "./queryKeys";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { globalAPI } from "./global";

let refCII: number = 0;
let reqCII: number = 0;

export const CO2Emission = (values: any) => {
  let cf1 = fuelCF(values.type_of_fuel1) ?? 0;
  let cf2 = fuelCF(values.type_of_fuel2) ?? 0;
  let cf3 = fuelCF(values.type_of_fuel3) ?? 0;
  return (
    values.fuel_type1_value * cf1 +
    values.fuel_type2_value * cf2 +
    values.fuel_type3_value * cf3
  );
};

export const successToast = (message: string) => {
  toast.dismiss();
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: 0,
    theme: "colored",
  });
};

export const VesselTypes: any[] = [
  { id: "1", label: "Bulk Carrier" },
  { id: "2", label: "Gas Carrier" },
  { id: "3", label: "Tanker" },
  { id: "4", label: "Container Ship" },
  { id: "5", label: "General Cargo Ship" },
  { id: "6", label: "Refrigerated Cargo Carrier" },
  { id: "7", label: "Combination Carrier" },
  { id: "8", label: "LNG Carrier" },
  { id: "9", label: "Ro-Ro Cargo Ship(VC)" },
  { id: "10", label: "Ro-Ro Cargo Ship" },
  { id: "11", label: "Ro-Ro Passenger Ship" },
  { id: "12", label: "Cruise Passenger Ship" },
];

export const Year: any[] = [
  { id: "1", label: "2023" },
  { id: "2", label: "2024" },
  { id: "3", label: "2025" },
  { id: "4", label: "2026" },
  { id: "5", label: "2027" },
  { id: "6", label: "2028" },
  { id: "7", label: "2029" },
  { id: "8", label: "2030" },
];

export const euaMultiplyingFactor = (year: string) => {
  if (year === "2024") {
    return 0.4;
  } else if (year === "2025") {
    return 0.7;
  } else {
    return 1;
  }
};

export const fuelCF = (fuel: number) => {
  if (fuel === 1) {
    return 3.206;
  } else if (fuel === 2) {
    return 3.151;
  } else if (fuel === 3) {
    return 3.114;
  } else if (fuel === 4) {
    return 3;
  } else if (fuel === 5) {
    return 3.03;
  } else if (fuel === 6) {
    return 2.75;
  } else if (fuel === 7) {
    return 1.375;
  } else if (fuel === 8) {
    return 1.913;
  }
};

export const calculateCapacity = (values: any) => {
  if (
    values.type_of_vessel === "Bulk Carrier" &&
    values.dwt_of_vessel >= 279000
  ) {
    return 279000;
  } else if (
    values.type_of_vessel === "Bulk Carrier" &&
    values.dwt_of_vessel < 279000
  ) {
    return values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "Gas Carrier" &&
    values.dwt_of_vessel >= 65000
  ) {
    return values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "Gas Carrier" &&
    values.dwt_of_vessel < 65000
  ) {
    return values.dwt_of_vessel;
  } else if (values.type_of_vessel === "Tanker") {
    return values.dwt_of_vessel;
  } else if (values.type_of_vessel === "Container Ship") {
    return values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "General Cargo Ship" &&
    values.dwt_of_vessel >= 20000
  ) {
    return values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "General Cargo Ship" &&
    values.dwt_of_vessel < 20000
  ) {
    return values.dwt_of_vessel;
  } else if (values.type_of_vessel === "Refrigerated Cargo Carrier") {
    return values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "Combination Carrier" &&
    values.dwt_of_vessel >= 40853
  ) {
    return values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "LNG Carrier" &&
    values.dwt_of_vessel >= 100000
  ) {
    return values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "LNG Carrier" &&
    100000 > values.dwt_of_vessel &&
    values.dwt_of_vessel >= 65000
  ) {
    return values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "LNG Carrier" &&
    values.dwt_of_vessel < 65000
  ) {
    return 65000;
  } else if (values.type_of_vessel === "Ro-Ro Cargo Ship(VC)") {
    return values.gross_tonnage;
  } else if (values.type_of_vessel === "Ro-Ro Cargo Ship") {
    return values.dwt_of_vessel;
  } else if (values.type_of_vessel === "Ro-Ro Passenger Ship") {
    return values.gross_tonnage;
  } else if (values.type_of_vessel === "Cruise Passenger Ship") {
    return values.gross_tonnage;
  }
};

export const calculateAttCII = (values: any) => {
  let cf1 = fuelCF(values.type_of_fuel1) ?? 0;
  let cf2 = fuelCF(values.type_of_fuel2) ?? 0;
  let cf3 = fuelCF(values.type_of_fuel3) ?? 0;
  let capacity: number = 0;
  if (
    values.type_of_vessel === "Bulk Carrier" &&
    values.dwt_of_vessel >= 279000
  ) {
    capacity = 279000;
  } else if (
    values.type_of_vessel === "Bulk Carrier" &&
    values.dwt_of_vessel < 279000
  ) {
    capacity = values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "Gas Carrier" &&
    values.dwt_of_vessel >= 65000
  ) {
    capacity = values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "Gas Carrier" &&
    values.dwt_of_vessel < 65000
  ) {
    capacity = values.dwt_of_vessel;
  } else if (values.type_of_vessel === "Tanker") {
    capacity = values.dwt_of_vessel;
  } else if (values.type_of_vessel === "Container Ship") {
    capacity = values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "General Cargo Ship" &&
    values.dwt_of_vessel >= 20000
  ) {
    capacity = values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "General Cargo Ship" &&
    values.dwt_of_vessel < 20000
  ) {
    capacity = values.dwt_of_vessel;
  } else if (values.type_of_vessel === "Refrigerated Cargo Carrier") {
    capacity = values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "Combination Carrier" &&
    values.dwt_of_vessel >= 40853
  ) {
    capacity = values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "LNG Carrier" &&
    values.dwt_of_vessel >= 100000
  ) {
    capacity = values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "LNG Carrier" &&
    100000 > values.dwt_of_vessel &&
    values.dwt_of_vessel >= 65000
  ) {
    capacity = values.dwt_of_vessel;
  } else if (
    values.type_of_vessel === "LNG Carrier" &&
    values.dwt_of_vessel < 65000
  ) {
    capacity = 65000;
  } else if (values.type_of_vessel === "Ro-Ro Cargo Ship(VC)") {
    capacity = values.gross_tonnage;
  } else if (values.type_of_vessel === "Ro-Ro Cargo Ship") {
    capacity = values.dwt_of_vessel;
  } else if (values.type_of_vessel === "Ro-Ro Passenger Ship") {
    capacity = values.gross_tonnage;
  } else if (values.type_of_vessel === "Cruise Passenger Ship") {
    capacity = values.gross_tonnage;
  }
  return (
    (values.fuel_type1_value * cf1 +
      values.fuel_type2_value * cf2 +
      values.fuel_type3_value * cf3) /
    (capacity * values.distance_travelled)
  );
};

export const calculateRefCII = (
  type: string | null,
  dwt: number,
  gt: number
) => {
  let a: number = 0;
  let c: number = 0;
  let capacity: number = 0;
  if (type === "Bulk Carrier" && dwt >= 279000) {
    a = 4745;
    c = 0.622;
    capacity = 279000;
  } else if (type === "Bulk Carrier" && dwt < 279000) {
    a = 4745;
    c = 0.622;
    capacity = dwt;
  } else if (type === "Gas Carrier" && dwt >= 65000) {
    a = 144050000000;
    c = 2.071;
    capacity = dwt;
  } else if (type === "Gas Carrier" && dwt < 65000) {
    a = 8104;
    c = 0.639;
    capacity = dwt;
  } else if (type === "Tanker") {
    a = 5247;
    c = 0.61;
    capacity = dwt;
  } else if (type === "Container Ship") {
    a = 1984;
    c = 0.489;
    capacity = dwt;
  } else if (type === "General Cargo Ship" && dwt >= 20000) {
    a = 31948;
    c = 0.792;
    capacity = dwt;
  } else if (type === "General Cargo Ship" && dwt < 20000) {
    a = 588;
    c = 0.3885;
    capacity = dwt;
  } else if (type === "Refrigerated Cargo Carrier") {
    a = 4600;
    c = 0.557;
    capacity = dwt;
  } else if (type === "Combination Carrier" && dwt >= 40853) {
    a = 40853;
    c = 0.812;
    capacity = dwt;
  } else if (type === "LNG Carrier" && dwt >= 100000) {
    a = 9.827;
    c = 0;
    capacity = dwt;
  } else if (type === "LNG Carrier" && 100000 > dwt && dwt >= 65000) {
    a = 144790000000000;
    c = 2.673;
    capacity = dwt;
  } else if (type === "LNG Carrier" && dwt < 65000) {
    a = 144790000000000;
    c = 2.673;
    capacity = 65000;
  } else if (type === "Ro-Ro Cargo Ship(VC)") {
    a = 5739;
    c = 0.631;
    capacity = gt;
  } else if (type === "Ro-Ro Cargo Ship") {
    a = 10952;
    c = 0.637;
    capacity = dwt;
  } else if (type === "Ro-Ro Passenger Ship") {
    a = 7540;
    c = 0.587;
    capacity = gt;
  } else if (type === "Cruise Passenger Ship") {
    a = 930;
    c = 0.383;
    capacity = gt;
  }
  return (refCII = a * Math.pow(capacity, -c));
};

export const calculateReqCII = (values: any) => {
  refCII = calculateRefCII(
    values.type_of_vessel,
    values.dwt_of_vessel,
    values.gross_tonnage
  );
  let z: number = 0;
  if (values.year === "2023") {
    z = 5;
  } else if (values.year === "2024") {
    z = 7;
  } else if (values.year === "2025") {
    z = 9;
  } else if (values.year === "2026") {
    z = 11;
  } else if (values.year === "2027") {
    z = 14;
  } else if (values.year === "2028") {
    z = 17;
  } else if (values.year === "2029") {
    z = 20;
  } else if (values.year === "2030") {
    z = 23;
  }
  return (reqCII = refCII * ((100 - z) / 100));
};

export function removeBodyCss() {
  document.body.classList.add("no_padding");
}

export function tog_backdrop(setModalState: any) {
  setModalState(false);
  removeBodyCss();
}

export const CIICategory = (values: any, ratio: any) => {
  if (values.type_of_vessel === "Bulk Carrier") {
    if (ratio >= 0 && ratio <= 0.86) {
      return "A";
    } else if (ratio > 0.86 && ratio <= 0.94) {
      return "B";
    } else if (ratio > 0.94 && ratio <= 1.06) {
      return "C";
    } else if (ratio > 1.06 && ratio <= 1.18) {
      return "D";
    } else if (ratio > 1.18) {
      return "E";
    }
  } else if (values.type_of_vessel === "Gas Carrier") {
    if (values.dwt_of_vessel >= 65000) {
      if (ratio >= 0 && ratio <= 0.81) {
        return "A";
      } else if (ratio > 0.81 && ratio <= 0.91) {
        return "B";
      } else if (ratio > 0.91 && ratio <= 1.12) {
        return "C";
      } else if (ratio > 1.12 && ratio <= 1.44) {
        return "D";
      } else if (ratio > 1.44) {
        return "E";
      }
    } else if (values.dwt_of_vessel < 65000) {
      if (ratio >= 0 && ratio <= 0.85) {
        return "A";
      } else if (ratio > 0.85 && ratio <= 0.95) {
        return "B";
      } else if (ratio > 0.95 && ratio <= 1.06) {
        return "C";
      } else if (ratio > 1.06 && ratio <= 1.25) {
        return "D";
      } else if (ratio > 1.25) {
        return "E";
      }
    }
  } else if (values.type_of_vessel === "Tanker") {
    if (ratio >= 0 && ratio <= 0.82) {
      return "A";
    } else if (ratio > 0.82 && ratio <= 0.93) {
      return "B";
    } else if (ratio > 0.93 && ratio <= 1.08) {
      return "C";
    } else if (ratio > 1.08 && ratio <= 1.28) {
      return "D";
    } else if (ratio > 1.28) {
      return "E";
    }
  } else if (values.type_of_vessel === "Container Ship") {
    if (ratio >= 0 && ratio <= 0.83) {
      return "A";
    } else if (ratio > 0.83 && ratio <= 0.94) {
      return "B";
    } else if (ratio > 0.94 && ratio <= 1.07) {
      return "C";
    } else if (ratio > 1.07 && ratio <= 1.19) {
      return "D";
    } else if (ratio > 1.19) {
      return "E";
    }
  } else if (values.type_of_vessel === "General Cargo Ship") {
    if (ratio >= 0 && ratio <= 0.83) {
      return "A";
    } else if (ratio > 0.83 && ratio <= 0.94) {
      return "B";
    } else if (ratio > 0.94 && ratio <= 1.06) {
      return "C";
    } else if (ratio > 1.06 && ratio <= 1.19) {
      return "D";
    } else if (ratio > 1.19) {
      return "E";
    }
  } else if (values.type_of_vessel === "Refrigerated Cargo Carrier") {
    if (ratio >= 0 && ratio <= 0.78) {
      return "A";
    } else if (ratio > 0.78 && ratio <= 0.91) {
      return "B";
    } else if (ratio > 0.91 && ratio <= 1.07) {
      return "C";
    } else if (ratio > 1.07 && ratio <= 1.2) {
      return "D";
    } else if (ratio > 1.2) {
      return "E";
    }
  } else if (values.type_of_vessel === "Combination Carrier") {
    if (ratio >= 0 && ratio <= 0.78) {
      return "A";
    } else if (ratio > 0.78 && ratio <= 0.96) {
      return "B";
    } else if (ratio > 0.96 && ratio <= 1.06) {
      return "C";
    } else if (ratio > 1.06 && ratio <= 1.14) {
      return "D";
    } else if (ratio > 1.14) {
      return "E";
    }
  } else if (values.type_of_vessel === "LNG Carrier") {
    if (values.dwt_of_vessel >= 100000) {
      if (ratio >= 0 && ratio <= 0.89) {
        return "A";
      } else if (ratio > 0.89 && ratio <= 0.98) {
        return "B";
      } else if (ratio > 0.98 && ratio <= 1.06) {
        return "C";
      } else if (ratio > 1.06 && ratio <= 1.13) {
        return "D";
      } else if (ratio > 1.13) {
        return "E";
      }
    } else if (values.dwt_of_vessel < 100000) {
      if (ratio >= 0 && ratio <= 0.78) {
        return "A";
      } else if (ratio > 0.78 && ratio <= 0.92) {
        return "B";
      } else if (ratio > 0.92 && ratio <= 1.1) {
        return "C";
      } else if (ratio > 1.1 && ratio <= 1.37) {
        return "D";
      } else if (ratio > 1.37) {
        return "E";
      }
    }
  } else if (values.type_of_vessel === "Ro-Ro Cargo Ship(VC)") {
    if (ratio >= 0 && ratio <= 0.86) {
      return "A";
    } else if (ratio > 0.86 && ratio <= 0.94) {
      return "B";
    } else if (ratio > 0.94 && ratio <= 1.06) {
      return "C";
    } else if (ratio > 1.06 && ratio <= 1.16) {
      return "D";
    } else if (ratio > 1.16) {
      return "E";
    }
  } else if (values.type_of_vessel === "Ro-Ro Cargo Ship") {
    if (ratio >= 0 && ratio <= 0.66) {
      return "A";
    } else if (ratio > 0.66 && ratio <= 0.9) {
      return "B";
    } else if (ratio > 0.9 && ratio <= 1.11) {
      return "C";
    } else if (ratio > 1.11 && ratio <= 1.41) {
      return "D";
    } else if (ratio > 1.41) {
      return "E";
    }
  } else if (values.type_of_vessel === "Ro-Ro Passenger Ship") {
    if (ratio >= 0 && ratio <= 0.72) {
      return "A";
    } else if (ratio > 0.72 && ratio <= 0.9) {
      return "B";
    } else if (ratio > 0.9 && ratio <= 1.12) {
      return "C";
    } else if (ratio > 1.12 && ratio <= 1.41) {
      return "D";
    } else if (ratio > 1.41) {
      return "E";
    }
  } else if (values.type_of_vessel === "Cruise Passenger Ship") {
    if (ratio >= 0 && ratio <= 0.87) {
      return "A";
    } else if (ratio > 0.87 && ratio <= 0.95) {
      return "B";
    } else if (ratio > 0.95 && ratio <= 1.06) {
      return "C";
    } else if (ratio > 1.06 && ratio <= 1.16) {
      return "D";
    } else if (ratio > 1.16) {
      return "E";
    }
  }
};

export const loadFuelTypes = async () => {
  try {
    const response = await globalAPI.get(queryKeys.FuelTypesMaster.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const loadVesselTypes = async () => {
  try {
    const response = await globalAPI.get(queryKeys.VesselTypesMaster.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
